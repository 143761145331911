.panels {
  height: 480px;
  max-height: 60vh;
}
.panelScroll {
  height: 100%;
  width: 100%;
}
.maxWidth {
  max-width: 768px;
}
.dialog {
  text-shadow: none;
  width: 500px;
  min-height: 750px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .dialog {
    margin: 0;
    max-width: 100vw;
    min-height: 100vh;
  }
}
.iframeSection {
  display: flex;
  padding: 0;
  height: 750px;
}
@media (max-width: 768px) {
  .iframeSection {
    min-height: 100vh;
  }
}
.iframeSection iframe {
  border: 0;
  width: 100%;
}
/*# sourceMappingURL=src/pages/payments/card/index.css.map */